<template>
  <div>
    <vx-card class="mb-4">
      <div class="flex flex-col gap-4">
        <div class="pb-8">
          <div class="flex flex-col gap-2">
            <div class="w-full">
              <vs-input
                id="certified_name"
                v-validate="'required'"
                :label="$t('certified.name')"
                v-model="model.name"
                class="w-full required" />
              <span class="text-danger text-sm" v-show="errors.has('certified_name')">{{errors.first('certified_name')}}</span>
            </div>
            <div class="w-full">
              <div class="form-group">
                <label class="control-label vs-input--label">{{$t('certified_emit.despatch')}}</label>
                <div class="mb-1">
                  <vs-radio v-model="model.release_st"
                            vs-name="release_st"
                            v-for="item in releaseStList"
                            :key="item.value"
                            :label="item.text"
                            :vs-value="item.value"
                            class="mr-2">{{item.text}}</vs-radio>
                </div>
                <div class="flex">
                  <div class="form-group">
                    <vs-input
                      id="duration"
                      type="number"
                      v-validate="'numeric|max:4'"
                      maxlength="4"
                      @keypress="isNumberInteger"
                      :label="$t('certified.duration_in_day')"
                      v-model="model.duration"/>
                      <span class="text-danger text-sm" v-show="errors.has('duration')">{{errors.first('duration')}}</span>
                    </div>
                </div>
              </div>
            </div>
            <div class="w-full">
              <select-suggestion
                :max="20"
                :class="institution ? 'flex-grow' : 'flex-grow required'"
                column="name"
                model="Institution"
                :label="$t('fields.institution')"
                v-model="institution"
                :appendClearOption="true"
                ref="select_institution"
                placeholderText="Digite o nome da instituição"/>
              </div>
            <div class="w-full">
              <select-suggestion
              :max="20"
              :class="questionnaire ? 'flex-grow' : 'flex-grow required'"
              column="name,description"
              model="ContentQuestionnaire"
              :label="$t('Questionnaire')"
              v-model="questionnaire"
              :appendClearOption="true"
              ref="select_questionnaire"
              placeholderText="Digite o nome..."/>
              <span
                class="text-danger text-sm"
                v-show="errors.has('questionnaire_id')">{{ errors.first('questionnaire_id') }}</span>
            </div>
            <div class="w-full">
              <vs-textarea
                id="description"
                :label="$t('certified.description')"
                v-model="model.description" />
              <span class="text-danger text-sm" v-show="errors.has('description')">{{errors.first('description')}}</span>
            </div>
          </div>
          <div v-if="isAdmin()">
          </div>

          <div class="w-full flex gap-2 justify-end">
            <vs-button
              v-if="!isEdit()"
              :disabled="!validateForm"
              v-permission="'certifieds.create'"
              @click="createOrUpdate">
              {{ $t('action.save') }}
            </vs-button>
            <vs-button
              v-if="isEdit()"
              :disabled="!validateForm"
              v-permission="'certifieds.edit'"
              @click="createOrUpdate">
              {{ $t('action.save') }}
            </vs-button>

            <vs-button
              type="border"
              @click="cancel">
              {{ $t('common.back') }}
            </vs-button>
          </div>
          <certified-legend v-if="isEdit()"></certified-legend>
        </div>
        <div class="flex flex-wrap items-center" v-if="isEdit()">
          <vue-dropzone
            id="upload"
            ref="fileUploadZone"
            v-on:vdropzone-success="successSendFile"
            v-on:vdropzone-sending="sending"
            v-on:vdropzone-error="error"
            v-on:vdropzone-file-added="added"
            v-on:vdropzone-queue-complete="complete"
            :use-font-awesome="true"
            :options="dropzoneOptions"
            :use-custom-dropzone-options="true"
            :max_number_of_files="1"
            :useCustomSlot="true"
            style="width: 100%; margin-bottom: 10px">
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title">
                {{ $t('arraste-e-solte-para-enviar-o-arquivo') }}
              </h3>
              <div class="subtitle">
                {{ $t('ou-selecione-um-arquivo-do-seu-computador') }}
              </div>
            </div>
          </vue-dropzone>
        </div>
        <div class="mt-2 w-full " v-if="isEdit">
          <media-list :medias="files"
                      :show-delete="false"
                      :action-on-click="false" />
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>

import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import standard from '@/services/standard'
import { isAdmin } from '@/util/Util'
import { isNumberInteger } from '@/util/NumberUtil'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import SelectSuggestion from '@/components/SelectSuggestion.vue'

import CertifiedService from '@/services/api/CertifiedService'
import MediaList from '@/components/media/MediaList.vue'
import CertifiedLegend from './CertifiedLegend.vue'

export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,
    MediaList,
    SelectSuggestion,
    CertifiedLegend,
    vueDropzone: vue2Dropzone,
  },
  props: {
    id: {
      default: null,
      type: Number
    }
  },

  data: () => ({
    service: null,
    dropzoneOptions: {},
    model: {
      id: null,
      name : '',
      release_st : 'AT', //Default
      description: '',
      duration: ''
    },
    files: [],
    questionnaire: null,
    institution: null,
    releaseStList: [
      { text: 'Automático', value: 'AT' },
      { text: 'Manual', value: 'MN' },
      { text: 'Após Regra', value: 'AR' },
    ],
  }),
  computed: {
    validateForm() {
      return !this.isEmpty(this.model.name)
            && !this.isEmpty(_.get(this.questionnaire, 'id'))
            && !this.isEmpty(_.get(this.institution, 'id'))
    }
  },
  beforeMount() {
    this.dropzoneOptions = {
      url: `${process.env.VUE_APP_API_BASE_URL}/api/v1/certifieds/${this.id}/files`,
      thumbnailWidth: 150,
      addRemoveLinks: true,
      createImageThumbnails: true,
      timeout: 180000,
      maxFiles: 1,
      headers: { Authorization: `Bearer ${standard.token()}` },
      acceptedFiles: null
    }
  },
  mounted() {
    this.service = CertifiedService.build(this.$vs)
    if (this.id) {
      this.loadData(this.id)
    }
  },

  methods: {
    loadData(id) {
      this.$vs.loading()

      this.service.read(id).then(
        response => {
          this.model = response
          this.files = response.files
          this.institution = response.institution
          this.questionnaire = {id : response.certified_commit.questionnaire_id, name: response.certified_commit.questionnaire_name}
        },
        error => {

        }
      ).finally(
        () => {
          this.$vs.loading.close()
        }
      )
    },
    createOrUpdate() {
      this.$vs.loading()

      const modelData = {
          id: this.model.id,
          name: this.model.name,
          release_st : this.model.release_st,
          description: this.model.description,
          questionnaire_id: this.questionnaire.id,
          institution_id: this.institution.id,
          duration: this.model.duration,
        }

      this.service.createOrUpdate(modelData).then(
          data => {
            this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
            this.$router.push(`/certifieds/${data.id}/edit`)
          },
          error => {
            this.showErrors(this.$validator, error)
            this.notifyError(
              this.$vs,
              this.$t('nao-foi-possivel-realizar-esta-operacao')
            )
          }
        ).finally(
          () => {this.$vs.loading.close()}
        )
    },
    cancel() {
      this.$router.push('/certifieds')
    },
    isAdmin() {
      return isAdmin()
    },
    isEdit(){
      return !_.isNil(this.id)
    },
    isNumberInteger(val){
      return isNumberInteger(val)
    },
    mounteQuestionnaireToSave(){
      let q = []
      this.questionnaires.forEach((questionnaireTag) => {
        this.addQuestionnaire(questionnaireTag.data)
      })
    },
    successSendFile(file, data) {
      this.files = []
      this.files.push(data)
      this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
      //remove file trash
      this.$refs.fileUploadZone.removeAllFiles()
    },
  }
}
</script>

<style>
.vs-list--header{
  display: inline !important;
}

</style>
