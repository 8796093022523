var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vx-card", { staticClass: "mb-4" }, [
        _c("div", { staticClass: "flex flex-col gap-4" }, [
          _c(
            "div",
            { staticClass: "pb-8" },
            [
              _c("div", { staticClass: "flex flex-col gap-2" }, [
                _c(
                  "div",
                  { staticClass: "w-full" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "w-full required",
                      attrs: {
                        id: "certified_name",
                        label: _vm.$t("certified.name"),
                      },
                      model: {
                        value: _vm.model.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "name", $$v)
                        },
                        expression: "model.name",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("certified_name"),
                            expression: "errors.has('certified_name')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("certified_name")))]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "w-full" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      { staticClass: "control-label vs-input--label" },
                      [_vm._v(_vm._s(_vm.$t("certified_emit.despatch")))]
                    ),
                    _c(
                      "div",
                      { staticClass: "mb-1" },
                      _vm._l(_vm.releaseStList, function (item) {
                        return _c(
                          "vs-radio",
                          {
                            key: item.value,
                            staticClass: "mr-2",
                            attrs: {
                              "vs-name": "release_st",
                              label: item.text,
                              "vs-value": item.value,
                            },
                            model: {
                              value: _vm.model.release_st,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "release_st", $$v)
                              },
                              expression: "model.release_st",
                            },
                          },
                          [_vm._v(_vm._s(item.text))]
                        )
                      }),
                      1
                    ),
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "numeric|max:4",
                                expression: "'numeric|max:4'",
                              },
                            ],
                            attrs: {
                              id: "duration",
                              type: "number",
                              maxlength: "4",
                              label: _vm.$t("certified.duration_in_day"),
                            },
                            on: { keypress: _vm.isNumberInteger },
                            model: {
                              value: _vm.model.duration,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "duration", $$v)
                              },
                              expression: "model.duration",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("duration"),
                                  expression: "errors.has('duration')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [_vm._v(_vm._s(_vm.errors.first("duration")))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "w-full" },
                  [
                    _c("select-suggestion", {
                      ref: "select_institution",
                      class: _vm.institution
                        ? "flex-grow"
                        : "flex-grow required",
                      attrs: {
                        max: 20,
                        column: "name",
                        model: "Institution",
                        label: _vm.$t("fields.institution"),
                        appendClearOption: true,
                        placeholderText: "Digite o nome da instituição",
                      },
                      model: {
                        value: _vm.institution,
                        callback: function ($$v) {
                          _vm.institution = $$v
                        },
                        expression: "institution",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "w-full" },
                  [
                    _c("select-suggestion", {
                      ref: "select_questionnaire",
                      class: _vm.questionnaire
                        ? "flex-grow"
                        : "flex-grow required",
                      attrs: {
                        max: 20,
                        column: "name,description",
                        model: "ContentQuestionnaire",
                        label: _vm.$t("Questionnaire"),
                        appendClearOption: true,
                        placeholderText: "Digite o nome...",
                      },
                      model: {
                        value: _vm.questionnaire,
                        callback: function ($$v) {
                          _vm.questionnaire = $$v
                        },
                        expression: "questionnaire",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("questionnaire_id"),
                            expression: "errors.has('questionnaire_id')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("questionnaire_id")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "w-full" },
                  [
                    _c("vs-textarea", {
                      attrs: {
                        id: "description",
                        label: _vm.$t("certified.description"),
                      },
                      model: {
                        value: _vm.model.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "description", $$v)
                        },
                        expression: "model.description",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("description"),
                            expression: "errors.has('description')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("description")))]
                    ),
                  ],
                  1
                ),
              ]),
              _vm.isAdmin() ? _c("div") : _vm._e(),
              _c(
                "div",
                { staticClass: "w-full flex gap-2 justify-end" },
                [
                  !_vm.isEdit()
                    ? _c(
                        "vs-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: "certifieds.create",
                              expression: "'certifieds.create'",
                            },
                          ],
                          attrs: { disabled: !_vm.validateForm },
                          on: { click: _vm.createOrUpdate },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("action.save")) + " ")]
                      )
                    : _vm._e(),
                  _vm.isEdit()
                    ? _c(
                        "vs-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: "certifieds.edit",
                              expression: "'certifieds.edit'",
                            },
                          ],
                          attrs: { disabled: !_vm.validateForm },
                          on: { click: _vm.createOrUpdate },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("action.save")) + " ")]
                      )
                    : _vm._e(),
                  _c(
                    "vs-button",
                    { attrs: { type: "border" }, on: { click: _vm.cancel } },
                    [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
                  ),
                ],
                1
              ),
              _vm.isEdit() ? _c("certified-legend") : _vm._e(),
            ],
            1
          ),
          _vm.isEdit()
            ? _c(
                "div",
                { staticClass: "flex flex-wrap items-center" },
                [
                  _c(
                    "vue-dropzone",
                    {
                      ref: "fileUploadZone",
                      staticStyle: { width: "100%", "margin-bottom": "10px" },
                      attrs: {
                        id: "upload",
                        "use-font-awesome": true,
                        options: _vm.dropzoneOptions,
                        "use-custom-dropzone-options": true,
                        max_number_of_files: 1,
                        useCustomSlot: true,
                      },
                      on: {
                        "vdropzone-success": _vm.successSendFile,
                        "vdropzone-sending": _vm.sending,
                        "vdropzone-error": _vm.error,
                        "vdropzone-file-added": _vm.added,
                        "vdropzone-queue-complete": _vm.complete,
                      },
                    },
                    [
                      _c("div", { staticClass: "dropzone-custom-content" }, [
                        _c("h3", { staticClass: "dropzone-custom-title" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("arraste-e-solte-para-enviar-o-arquivo")
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", { staticClass: "subtitle" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "ou-selecione-um-arquivo-do-seu-computador"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isEdit
            ? _c(
                "div",
                { staticClass: "mt-2 w-full " },
                [
                  _c("media-list", {
                    attrs: {
                      medias: _vm.files,
                      "show-delete": false,
                      "action-on-click": false,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }